import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCard from "../components/blog/blogcard";
// import HowTo from "../components/blog/howto";
// import Spotlight from "../components/blog/spotlight";


const InsightsPage = ({data, path}) => (
  <Layout
    title="Startup Founder Insights" subtitle={'Content for community-first founders, by a community-first founder'}
    includeBanner={true} lightHeader={true}
    seoComponent={<SEO path={path} title="Startup Founder Insights" description="Learn best practices for community-first entrepreneurship from the founder of GummySearch" />}
  >
    {/*<h2 className="text-5xl font-extrabold text-center">GummySearch Insights</h2>
    <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4 text-center">
      Achieve your goals with helpful process & product walkthroughs
    </p>*/}

    <div className="mt-12">
      <ul className={`mt-2 grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8 md:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8`}>
        {data.posts.edges.map((edge) => (
          <BlogCard key={edge.node.frontmatter.slug} node={edge.node} />
        ))}
      </ul>
    </div>
  </Layout>
)

export const query = graphql`
query {
  posts: allMdx(
    filter: { frontmatter: { type : {eq: "insight"}, published: {ne: ""}}},
    sort: { order: DESC, fields: [frontmatter___published] }
  ) {
    edges {
      node {
        frontmatter {
          slug
          published
          author
          title
          type
          description
          imageUrl
        }
      }
    }
  }
}
`

export default InsightsPage;
